import Search from "./modules/Search.js";
import MyNotes from "./modules/MyNotes.js";
import Like from "./modules/Like.js";

const myNotes = new MyNotes()
const like = new Like()

document.addEventListener('DOMContentLoaded', () => {
    const search = new Search();
});

